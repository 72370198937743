import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import ThemeContext from '../../context'
import './styles.scss'

class Hero extends React.Component {

    static contextType = ThemeContext

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.height}}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>9 Years of Experience</h3>
                            <div className="separator" />
                            <p>Looking to take your idea to the next level, but hesitant about where to start? Or is your web/mobile app due for an upgrade with a sleek, modern re-design that gives it the edge it needs? That's exactly what I'm here for. I work on high-performance applications that scale. I also follow industry standards on the latest and greatest technologies to make thoughtful product decisions, while keeping an open mind to working with whatever technologies you may feel comfortable with. I am a senior software engineer with 9 years of experience specializing in React Native, React, Node.js, Laravel and Vue.js.</p>
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/jimmywaynefoster')}/>
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/jimmy-foster-224b40250/')} />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                            <div className="line-text">
                                <h4>My Skills</h4>
                            </div>
                            <div className="skills-container">
                                <Progress name="React Native" value={100} delay={1100} />
                                <Progress name="React" value={100} delay={1100} />
                                <Progress name="Laravel" value={100} delay={1100} />
                                <Progress name="TypeScript" value={100} delay={1100} />
                                <Progress name="Vue" value={100} delay={1100} />
                                <Progress name="Node" value={100} delay={1100} />
                            </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero