import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      error: false,
      show: false,
      msg: '',
      isSubmitted: false,
    }
    this.show = this.show.bind(this)
  }
  static contextType = ThemeContext

  show() {
    this.setState({ show: true })
  }

  check(val, email = false) {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(val)
    if (!isValid && email && val?.length) {
      return 'Invalid Email Address'
    }
    return !(this.state.error && val === '')
  }
  async submit() {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)
    const shouldSubmit = !(
      this.state.name === '' ||
      this.state.email === '' ||
      this.state.message === '' ||
      this.state.subject === '' ||
      !isValid
    )
    this.setState({ error: !shouldSubmit })
    if (!shouldSubmit) return null
    const formData = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      subject: this.state.subject,
    }
    await axios({
      method: 'POST',
      url: 'https://getform.io/f/2bee3137-5f2a-491c-993a-ab1cd29c9afa',
      data: formData,
    })
    this.setState({ isSubmitted: true })
  }
  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="contact"
        style={{ height: this.context.height }}
      >
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Contact"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.show}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={5} className="form">
            {this.form()}
          </Col>
          <Col md={5} className="map">
            {this.map()}
          </Col>
        </Row>
      </section>
    )
  }

  form() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <AnimationContainer delay={0} animation="fadeInUp fast">
          <div className="form-container">
            <div className="line-text">
              <h4>Get In Touch</h4>
              <AnimationContainer delay={50} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className={'name form-input_spaced'}
                    placeholder="Name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                  <span className={'form-input_error'}>
                    {this.check(this.state.name) == '' ? 'Name Required' : ''}
                  </span>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={100} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="email"
                    className={'email form-input_spaced'}
                    placeholder="Email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <span className={'form-input_error'}>
                    {this.check(this.state.email, true) == ''
                      ? 'Email Required'
                      : this.check(this.state.email, true) || ''}
                  </span>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={150} animation="fadeInUp fast">
                <div className="form-group">
                  <input
                    type="text"
                    className="phone"
                    placeholder="Subject"
                    onChange={(e) => this.setState({ subject: e.target.value })}
                  />
                  <span className={'form-input_error'}>
                    {this.check(this.state.subject) == ''
                      ? 'Subject Required'
                      : ''}
                  </span>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={200} animation="fadeInUp fast">
                <div className="form-group">
                  <textarea
                    className={'message input_spaced'}
                    placeholder="Message"
                    onChange={(e) => this.setState({ message: e.target.value })}
                  ></textarea>
                  <span className={'form-input_error'}>
                    {this.check(this.state.message) == ''
                      ? 'Message Required'
                      : ''}
                  </span>
                </div>
              </AnimationContainer>
              <AnimationContainer delay={250} animation="fadeInUp fast">
                <div className="submit">
                  <button
                    className={`hover-button ${
                      this.state.error ? 'error' : ''
                    }`}
                    onClick={() => this.submit()}
                  >
                    <span>Send Message</span>
                  </button>
                </div>
              </AnimationContainer>
              {this.state.isSubmitted && (
                <p className="form-output-success">
                  An email has been sent. Will contact you soon. Thank you!
                </p>
              )}
            </div>
          </div>
        </AnimationContainer>
      )
    }
  }

  map() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <AnimationContainer
          delay={1000}
          animation="fadeIn fast"
          height={this.context.height}
        >
          <iframe
            title="map"
            width="100%"
            height="100%"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Pilot+Point,+TX+76258,+USA&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          />
        </AnimationContainer>
      )
    }
  }
}

export default Contact
